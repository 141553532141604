import M from 'materialize-css';
import React, {
  useLayoutEffect,
  useState,
  useRef,
  useEffect,
  MouseEvent,
  Dispatch,
  SetStateAction,
} from 'react';
import api from '../../../common/api/index.ts';
import { AddressType } from '../../../common/enums.ts';
import { State, TradingPartnerAddress } from '../../../common/models.ts';

interface props {
  addresses: Partial<TradingPartnerAddress>[];
  setAddresses: Dispatch<SetStateAction<Partial<TradingPartnerAddress>[]>>;
}

const Addresses = ({ addresses, setAddresses }: props) => {
  const initValues = (): Partial<TradingPartnerAddress> => ({
    stateId: 0,
    addressType: AddressType.physical,
    name: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    postalCode: '',
  });

  const addressModal = useRef<HTMLDivElement>(null);
  const stateListDropdown = useRef<HTMLSelectElement>(null);
  const [stateList, setStateList] = useState<State[]>([]);
  const [values, setValues] = useState(initValues());
  const [edit, setEdit] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);

  const getStateList = async () => {
    const result = await api.state.read.all();
    if (result) {
      setStateList(result);
    }
  };

  useEffect(() => {
    getStateList();
  }, []);

  useEffect( () => {
    const dropdown = stateListDropdown.current;
    if(dropdown){
      M.FormSelect.init(dropdown);
      return () => {
        M.FormSelect.getInstance(dropdown).destroy();
      }
    }
  }, [stateList])

  useLayoutEffect(() => {
    M.Modal.init(document.querySelectorAll('#add-address-modal'));
    M.FormSelect.init(document.querySelectorAll('select'));

    return () => {
      document.querySelectorAll('.modal').forEach((modal) => {
        M.Modal.getInstance(modal)?.destroy();
      });
    };
  }, []);

  const onChange = ({ target: { id, value } }) =>
    setValues((prev) => ({ ...prev, [id]: value }));

  const openModal = () => {
    if (addressModal.current) M.Modal.getInstance(addressModal.current)?.open();
  };

  const closeModal = (event?: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    if (addressModal.current)
      M.Modal.getInstance(addressModal.current)?.close();
  };

  const addAddress = (e) => {
    e?.preventDefault();
    openModal();
  };

  const saveAddress = (event?: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    if(edit){
      if(editIndex === null) {
        return;
      }
      setAddresses( prev => {
        prev.splice(editIndex, 1, values);
        return prev;
      })
    } else {
      setAddresses((prev) => [...prev, values]);
    }
    setEdit(false);
    setEditIndex(null);
    closeModal();
    setValues(initValues());
  };

  const cancel = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    closeModal();
    setValues(initValues());
  };

  const editAddress = (
    event: MouseEvent<HTMLAnchorElement>,
    index: number
  ) => {
    event?.preventDefault();
    setEdit(true);
    setEditIndex(index);
    setValues(addresses[index])
    openModal();
  };

  const deleteAddress = (
    event: MouseEvent<HTMLAnchorElement>,
    index: number
  ) => {
    event?.preventDefault();
    addresses.splice(index, 1);
    setAddresses([...addresses]);
  };

  return (
    <>
      <div className="row">
        <h5>Addresses</h5>
        <div className="divider" />
      </div>
      <div className="row">
        <table className="highlight">
          <thead>
            <tr>
              <th>Name</th>
              <th>Address 1</th>
              <th>Address 2</th>
              <th>City</th>
              <th>State</th>
              <th>Postal Code</th>
              <th>Type</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {addresses?.map((address, index) => (
              <tr key={JSON.stringify(address)}>
                <td style={{ padding: '2px' }}>{address.name}</td>
                <td style={{ padding: '2px' }}>{address.addressLineOne}</td>
                <td style={{ padding: '2px' }}>{address.addressLineTwo}</td>
                <td style={{ padding: '2px' }}>{address.city}</td>
                <td style={{ padding: '2px' }}>
                  {stateList.find((state) => state.id === parseInt(String(address.stateId)))
                    ?.name || ''}
                </td>
                <td style={{ padding: '2px' }}>{address.postalCode}</td>
                <td style={{ padding: '2px' }}>{address.addressType}</td>
                <td style={{ padding: '2px' }}>
                  <a href="/" onClick={(e) => editAddress(e, index)}>
                    edit
                  </a>
                </td>
                <td style={{ padding: '2px' }}>
                  <a href="/" onClick={(e) => deleteAddress(e, index)}>
                    delete
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col s12 m3 offset-m9">
          <a href="/" className="right" onClick={addAddress}>
            Add Address
          </a>
        </div>
      </div>
      <div ref={addressModal} id="add-address-modal" className="modal">
        <div className="modal-content">
          <div className="row">
            <h6 className="center">Add Address</h6>
            <div className="divider" />
          </div>
          <div className="row">
            <div className="input-field col s12">
              <input
                id="name"
                type="text"
                value={values.name}
                onChange={onChange}
              />
              <label htmlFor="name">Name (as it appears on address)</label>
            </div>
            <div className="row">
              <div className="input-field col s12 l8">
                <input
                  id="addressLineOne"
                  type="text"
                  value={values.addressLineOne}
                  onChange={onChange}
                />
                <label htmlFor="addressLineOne">Address Line One</label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12 l8">
                <input
                  id="addressLineTwo"
                  type="text"
                  value={values.addressLineTwo}
                  onChange={onChange}
                />
                <label htmlFor="addressLineTwo">Address Line Two</label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s8 l6">
                <input
                  id="city"
                  type="text"
                  value={values.city}
                  onChange={onChange}
                />
                <label htmlFor="city">City</label>
              </div>
              <div className="input-field col s4">
                <select id="stateId" value={values.stateId} onChange={onChange}>
                  <option value=""></option>
                  {stateList?.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </select>
                <label htmlFor="stateId">State</label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s6 l4">
                <input
                  id="postalCode"
                  type="text"
                  value={values.postalCode}
                  onChange={onChange}
                />
                <label htmlFor="postalCode">Postal Code</label>
              </div>
              <div className="input-field col s6 l4">
                <select
                  id="addressType"
                  value={values.addressType}
                  onChange={onChange}
                >
                  <option value={AddressType.physical}>
                    {AddressType.physical}
                  </option>
                  <option value={AddressType.mailTo}>
                    {AddressType.mailTo}
                  </option>
                  <option value={AddressType.billTo}>
                    {AddressType.billTo}
                  </option>
                  <option value={AddressType.other}>{AddressType.other}</option>
                </select>
                <label htmlFor="addressType">Address Type</label>
              </div>
            </div>
            <div className="row">
              <div className="col s6 l3 offset-l6">
                <a
                  href="/"
                  className="btn-small blue white-text waves-effect waves-light col s12"
                  onClick={saveAddress}
                >
                  {edit ? 'Save Changes' : 'Add Address'}
                </a>
              </div>
              <div className="col s6 l3">
                <a
                  href="/"
                  className="btn-small blue white-text waves-effect waves-light col s12"
                  onClick={cancel}
                >
                  Cancel
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addresses;
