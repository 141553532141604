import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import M from 'materialize-css';
import { toast } from '../../func';
import { useNavigate } from 'react-router-dom';
import DisplayTable from '../../components/DisplayTable.tsx';
import api from '../../common/api/index.ts';
const pageId = '_' + crypto.randomUUID();

const IncidentTracker = (props) => {

  const navigate = useNavigate();

  const [incidents, setIncidents] = useState([])

  const ref = useRef({
    abortController: new AbortController(),
  });

  const [searchValues, setSearchValues] = useState({
    serialNumber: '',
    incidentNumber: '',
    ndc: '',
    medName: '',
    drugDescription: '',
    drugUse: '',
    primaryIngredients: '',
    classification: '',
    dateOfInitialNotification: '',
    strength: '',
    dosageForm: '',
    quantityOfDrug: '',
    expirationDates: '',
    eventNotes: '',
    companyName: '',
    uniqueFacilityIdentifier: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
  });
  const [loading, setLoading] = useState(false);
  const [editRow, setEditRow] = useState(null);
  const [editField, setEditField] = useState({ label: '', value: '' });
  const [displayData, setDisplayData] = useState(null);
  const [warnings, setWarnings] = useState(props.warnings);
  const modalRef = useRef();

  const handleToast = (err) => {
    if (err?.response?.data) {
      toast(err.response.data);
    } else {
      console.log(err)
    }
  }

  const search = useCallback(async (e) => {
    e?.preventDefault();
    ref.current.abortController?.abort();
    ref.current.abortController = new AbortController();
    const abortSignal = ref.current.abortController.signal;
    setLoading(true);

    try {
      const incidents = await api.illegitReport.read.many.by.search(searchValues, abortSignal);
      setIncidents(incidents);
    } catch (err) {
      handleToast(err);
    } finally {
      setLoading(false)
    }

  }, [searchValues]);

  const onChange = ({ target: { id, value } }) => setSearchValues((p) => ({ ...p, [id]: value }));

  const editOnChange = (event) => {
    setEditField({ ...editField, value: event.target.value, })
  }

  const openEditModal = (row) => {
    const displayData = Object.entries(row).filter(([key, value]) => [...Object.keys(searchValues)].includes(key))
    setEditRow(row);
    setDisplayData(displayData);
    const modal = M.Modal.getInstance(modalRef.current);
    if (modal) {
      modal.open();
    }
  }

  const prepareIncident = (incidentId, type) => {
    closeModal();
    navigate(`/reportanincident/${incidentId}/${type}`);
  }

  const saveChanges = async () => {
    try {
      await api.illegitReport.update.one.by.id({ id: editRow.id, [editField.key]: editField.value });
      setIncidents(prev => {
        const index = prev.findIndex(i => i.id === editRow.id);
        if (index === -1) {
          return prev;
        }
        if (prev[index][editField.key].value && !editField.value) {
          setWarnings(props.warnings + 1);

        } else if (!prev[index][editField.key].value && editField.value) {
          setWarnings(props.warnings - 1);
        }

        prev[index][editField.key] = { ...prev[index][editField.key], value: editField.value }
        const displayData = Object.entries(prev[index]).filter(([key, value]) => [...Object.keys(searchValues)].includes(key))
        setDisplayData(displayData);

        return prev
      });
    } catch (err) {
      handleToast(err);
    } finally {
      setEditField({ label: '', value: '', key: '' });
    }

  }

  const closeModal = () => {
    const modal = M.Modal.getInstance(modalRef.current);
    if (modal) {
      modal.close();
    }
  }

  const getActions = (item) => {
    if (!item.edit) return;
    else return (<>
      {editField?.label === item.label ?
        <>
          <i
            className="hoverable material-icons"
            style={{
              cursor: 'pointer',
              borderRadius: '8px',
              padding: '4px'
            }}
            onClick={saveChanges}>
            save
          </i>
          <i
            className="hoverable material-icons"
            style={{
              cursor: 'pointer',
              borderRadius: '8px',
              padding: '4px'
            }}
            onClick={(event) => {
              setEditField(null)
            }}>
            cancel
          </i>
        </>
        :
        <i
          className="hoverable material-icons"
          style={{
            cursor: 'pointer',
            borderRadius: '8px'
          }}
          onClick={(event) => {
            setEditField({ ...item })
          }}>
          edit
        </i>
      }
    </>)
  };

  const getDisplayData = (item) => {
    return ({
      label: item.label,
      value: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          {editField?.label === item.label ?
            <input id={`${editField.key}`} value={editField.value} onChange={editOnChange} /> :
            item.value
          }
          <div
            style={{
              display: 'flex',
              width: '120px',
              marginLeft: "auto",
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            {getActions(item)}
          </div>
        </div>
      ),
    })
  };

  useLayoutEffect(() => {
    const modal = M.Modal.init(modalRef.current);
    if (modal) {
      return () => {
        if (modal) {
          modal.destroy();
        }
      }
    }
  }, [])

  useEffect(() => {
    search();
  }, [search]);

  useEffect(() => {
    if (props.warnings !== warnings) {
      props.setWarnings(warnings)
    }
  }, [warnings, props])

  return (
    <div>
      <div className="row">
        <div className="input-field col s12 m3 l2">
          <input
            id="serialNumber"
            className={`search-med-${pageId}`}
            type="text"
            value={searchValues.serialNumber}
            onChange={onChange}
          />
          <label htmlFor="serialNumber">Serial #</label>
        </div>
        <div className="input-field col s12 m3 l2">
          <input
            id="ndc"
            className={`search-med-${pageId}`}
            type="text"
            value={searchValues.ndc}
            onChange={onChange}
          />
          <label htmlFor="ndc">NDC</label>
        </div>
        <div className="input-field col s12 m3 l2">
          <input
            id="medName"
            className={`search-med-${pageId}`}
            type="text"
            value={searchValues.medName}
            onChange={onChange}
          />
          <label htmlFor="medName">Med Name</label>
        </div>
        <div className="input-field col s12 m3 l2">
          <input
            id="incidentNumber"
            className={`search-med-${pageId}`}
            type="text"
            value={searchValues.incidentNumber}
            onChange={onChange}
          />
          <label htmlFor="incidentNumber">Incident Number</label>
        </div>
        <div>
          <br />
          <button className="btn-small blue white-text waves-effect waves-light col s3 offset-s1" onClick={search}>Search</button>
        </div>
      </div>
      <div className="row">
        {loading ? (
          <div className="progress">
            <div className="indeterminate" />
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                <th></th>
                <th style={{ padding: '2px' }}>Serial #</th>
                <th style={{ padding: '2px' }}>Report Type</th>
                <th style={{ padding: '2px' }}>Incident Number</th>
                <th style={{ padding: '2px' }}>Med Name</th>
                <th style={{ padding: '2px' }}>NDC</th>
                <th style={{ padding: '2px' }}>Lot #</th>
                <th style={{ padding: '2px' }}>Date Created</th>
                <th style={{ padding: '2px' }}>Created By</th>
                <th style={{ padding: '2px' }}>Location</th>
              </tr>
            </thead>
            <tbody>
              {incidents?.map((row) => (
                <tr
                  key={row.id}
                  className="hoverable"
                  onClick={() => openEditModal(row)}
                  style={{ cursor: 'pointer' }}
                >
                  <td>{!row.incidentNumber.value ? <i style={{ color: 'red' }} className='material-icons'>warning</i> : null}</td>
                  <td>{row.serialNumber.value}</td>
                  <td>{row.reportType.value}</td>
                  <td>{row.incidentNumber.value}</td>
                  <td>
                    {row.productName.value}
                  </td>
                  <td>
                    {row.ndc.value}
                  </td>
                  <td>{row.lotNumber.value}</td>
                  <td>
                    {row.dateCreated.value}
                  </td>
                  <td>
                    {row.createdBy.value}
                  </td>
                  <td>{row.customerLocationName.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div ref={modalRef} id="report-edit" className="modal">
        <div className="modal-content">
          <div className="row">
            <h5 className="bolder"><i>Incident Info</i></h5>
          </div>
          <DisplayTable data={editRow ? displayData.map(item => getDisplayData(item[1])) : null} />
          <div className="row">
            <button className="btn-small green white-text waves-effect waves-light col s3" onClick={() => prepareIncident(editRow.id, 'TERMINATE')}>Terminate</button>
            <button className="btn-small blue white-text waves-effect waves-light col s4 offset-s1" onClick={() => prepareIncident(editRow.id, 'FOLLOWUP')}>Follow Up</button>
            <button className="btn-small red white-text waves-effect waves-light col s3  offset-s1" onClick={closeModal}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
};


export default IncidentTracker;