import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/login/Login';
import { useEffect, useState } from 'react';
import Sidenav from './components/layout/sidenav';
import Banner from './components/layout/banner';
import HomePage from './pages/HomePage/HomePage';
import AddMedPage from './pages/addMed/AddMedPage';
import MedDetailPage from './pages/medDetail/MedDetailPage';
import SearchMed from './pages/medSearch/MedSearch';
import SendPedigree from './pages/sendPedigree/sendPedigree';
import ReportAnIncident from './pages/reportAnIncident/reportAnIncident';
import IncidentTracker from './pages/incidentTracker/incidentTracker';
import SettingsPage from './pages/settings/SettingsPage';
import OrderUpload from "./pages/orders/orderUpload";
import OrderRouter from './pages/orders/orderRouter';
import DemoEpcisFileGenerator from "./pages/demoEpcisFileGenerator/DemoEpcisFileGenerator";
import TradingPartnerRouter from './pages/tradingPartners/TradingPartnerRouter.tsx';
import { toast } from './func.js';
import GlobalLocationNumbersRouter from './pages/globalLocationNumbers/globalLocationNumbersRouter.tsx';
import api from './common/api/index.ts';


const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [warnings, setWarnings] = useState(0);

  const logout = () => {
    localStorage.clear();
    setLoggedIn(false);
  }

  const getWarnings = async () => {
    try {
      const warnings = await api.illegitReport.read.count.by.userId();
      setWarnings(warnings)
    } catch (err) {
      toast(err?.response?.data);
    }
  }

  useEffect(() => {
    if (loggedIn) {
      getWarnings();
    }
  }, [loggedIn])

  return (
    <BrowserRouter>
      {loggedIn ?
        <>
          <Sidenav warnings={warnings} logout={logout} />
          <Banner />
          <div className='offset-for-sidenav'>
            <Routes>
              <Route path="/" element={<HomePage warnings={warnings} />} />
              <Route path="/inputmeds" element={<AddMedPage />} />
              <Route path="/meddetail/:medId" element={<MedDetailPage />} />
              <Route path="/searchmeds" element={<SearchMed />} />
              <Route path="/sendapedigree" element={<SendPedigree />} />
              <Route path="/sendapedigree/:serialNumber" element={<SendPedigree />} />
              <Route path="/reportanincident/:incidentId/:type" element={<ReportAnIncident setWarnings={setWarnings} warnings={warnings} />} />
              <Route path="/reportanincident" element={<ReportAnIncident setWarnings={setWarnings} warnings={warnings} />} />
              <Route path="/incidenttracker" element={<IncidentTracker setWarnings={setWarnings} warnings={warnings} />} />
              <Route path="/settings/*" element={<SettingsPage />} />
              <Route path="/orders/*" element={<OrderRouter />} />
              <Route path="/orderupload/*" element={<OrderUpload />} />
              <Route path='/tradingpartner/*' element={<TradingPartnerRouter />} />
              <Route path="/gln" element={<GlobalLocationNumbersRouter />} />
              {process.env.REACT_APP_NODE_ENV !== 'production' && <Route path="/demo/epcis/file/generator" element={<DemoEpcisFileGenerator />} />}
            </Routes>
          </div>
        </>
        :
        <Login setLoggedIn={setLoggedIn} />
      }
    </BrowserRouter>
  );
}

export default App;
