import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MyGLNs from './myGLNs.tsx';

const GlobalLocationNumbersRouter = () => (
  <Routes>
    <Route path="/" element={<MyGLNs />} />
  </Routes>
);

export default GlobalLocationNumbersRouter;
