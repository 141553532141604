import React, { CSSProperties } from 'react';
import { DisplayTableData } from '../common/models.ts';

interface props {
  data: DisplayTableData[] | undefined;
  style?: CSSProperties;
}

const DisplayTable = ({ data, style }: props) => (
  <div style={style || {}} className="row">
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        justifySelf: 'stretch',
      }}
    >
      {data?.map(({ label, value }, index) => (
        <div key={index} style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
          {label ? (
            typeof label === 'string' ? (
              <b
                style={{
                  borderBottom: '1px solid #ddd',
                  marginRight: '2vw',
                  padding: '4px 0px',
                  width: '12vw',
                  minWidth: '12vw',
                  textAlign: 'right',
                }}
              >
                {label}
              </b>
            ) : (
              <div
                style={{
                  borderBottom: '1px solid #ddd',
                  marginRight: '2vw',
                  padding: '4px 0px',
                  width: '12vw',
                  minWidth: '12vw',
                }}
              >
                {label}
              </div>
            )
          ) : null}
          {typeof value === 'string' ? (
            <span
              style={{
                borderBottom: '1px solid #ddd',
                padding: '4px 0px',
                overflowWrap: 'break-word',
                width: label ? 'CALC(100% - 14vw)' : '100%',
                textAlign: 'left',
                minHeight: '24px'
              }}
            >
              {value}
            </span>
          ) : (
            <div
              style={{
                borderBottom: '1px solid #ddd',
                padding: '4px 0px',
                overflowWrap: 'break-word',
                width: label ? 'CALC(100% - 14vw)' : '100%',
                textAlign: 'left',
                minHeight: '24px'
              }}
            >
              {value}
            </div>
          )}
        </div>
      ))}
    </div>
  </div>
);

export default DisplayTable;
