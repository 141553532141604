import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TradingPartners from './TradingPartners.jsx';
import General from './create/General.tsx';
import TradingPartner from './TradingPartner.tsx';

const TradingPartnerRouter = () => (
  <Routes>
    <Route path="/" element={<TradingPartners />} />
    <Route path="/create" element={<General />} />
    <Route path="/:id" element={<TradingPartner />} />
  </Routes>
);

export default TradingPartnerRouter;
