import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { logout, toast } from '../../func';
import { UserAuth } from "../models";


export const fetch = async <T>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  body?: { [key: string]: any } | FormData,
  responseType?: 'json' | 'text' | 'blob' | 'arraybuffer' | 'stream',
  signal?: AbortSignal
): Promise<T | null> => {
  try {
    const item = localStorage.getItem('auth_data');
    const authData = item && !(body instanceof FormData) ? JSON.parse(item) : {};
    const headers = item && body instanceof FormData ? JSON.parse(item) : {};
    const combinedBody = body instanceof FormData ? body : { ...authData, ...body };

    const requestConfig: AxiosRequestConfig = {
      url,
      method,
      headers,
      params: ['GET', 'DELETE'].includes(method) ? combinedBody : null,
      data: ['PUT', 'POST'].includes(method) ? combinedBody : null,
      signal,
      responseType
    };
    const response: AxiosResponse = await Axios.request(requestConfig);
    if (response.data?.type === 'GLOBAL_TOAST') toast(response.data);

    return response.data as T;
  } catch (err) {
    if (responseType === 'arraybuffer') {
      const textDecoder = new TextDecoder('utf-8');
      const parsedResponse = JSON.parse(textDecoder.decode(new Uint8Array(err?.response?.data)));
      if(parsedResponse?.type === 'GLOBAL_TOAST'){
        toast(parsedResponse);
      }
      return null;
    }
    logout(err);
    return null;
  }
};

export const getAuthData = (): UserAuth | null => {
    const localData: string | null = localStorage.getItem('auth_data');
    if (localData !== null){
        return JSON.parse(localData) as UserAuth;
    } else {
        return null;
    }
};
