import endpoints from '../endpoints.ts';
import { CustomerLocation } from '../models.ts';
import { fetch } from './helpers.ts';

const customer = {
  create: {},
  read: {
    many: {
      by: {
        userId: async (): Promise<CustomerLocation[] | null> => {
          const url = endpoints.customer.read.many.by.userId();
          return await fetch<CustomerLocation[]>(url, 'GET');
        },
      },
    },
  },
  update: {},
  delete: {},
};

export default customer;
