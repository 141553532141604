const endpoints = {
  customer: {
    create: {},
    read: {
      many: {
        by: {
          userId: () => `/api/v1/customer/read/many/by/userid`,
        },
      },
    },
    update: {},
    delete: {},
  },
  customerLocationGlobalLocationNumber: {
    create: {
      one: () => `/api/v1/customerlocationgloballocationnumber/create/one`,
    },
    read: {
      many: {
        by: {
          userId: () =>
            `/api/v1/customerlocationgloballocationnumber/read/many/by/userid`,
        },
      },
    },
    update: {
      one: () => `/api/v1/customerlocationgloballocationnumber/update/one`,
    },
    delete: {
      one: () => `/api/v1/customerlocationgloballocationnumber/delete/one`,
    },
  },
  state: {
    read: {
      all: () => `/api/v1/state/read/all`,
    },
  },
  tradingPartner: {
    create: {
      one: () => `/api/v1/tradingpartner/create/one`,
      tradingPartnerGlobalLocationNumber: () =>
        `/api/v1/tradingpartner/create/tradingpartnergloballocationnumber`,
      tradingPartnerAddress: () =>
        `/api/v1/tradingpartner/create/tradingpartneraddress`,
      tradingPartnerContact: () =>
        `/api/v1/tradingpartner/create/tradingpartnercontact`,
      tradingPartnerContactDetail: () =>
        `/api/v1/tradingpartner/create/tradingpartnercontactdetail`,
      tradingPartnerAccountNumber: () =>
        `/api/v1/tradingpartner/create/tradingpartneraccountnumber`,
    },
    read: {
      one: {
        tradingPartner: {
          by: {
            id: () => `/api/v1/tradingpartner/read/one/tradingpartner/by/id`,
          },
        },
      },
      many: {
        tradingPartners: {
          search: () =>
            `/api/v1/tradingpartner/read/many/tradingpartners/search`,
        },
      },
    },
    update: {
      one: {
        tradingPartner: () =>
          `/api/v1/tradingpartner/update/one/tradingpartner`,
        tradingPartnerGlobalLocationNumber: () =>
          `/api/v1/tradingpartner/update/one/tradingpartnergloballocationnumber`,
        tradingPartnerAddress: () =>
          `/api/v1/tradingpartner/update/one/tradingpartneraddress`,
        tradingPartnerContact: () =>
          `/api/v1/tradingpartner/update/one/tradingpartnercontact`,
        tradingPartnerAccountNumber: () =>
          `/api/v1/tradingpartner/update/one/tradingpartneraccountnumber`,
      },
    },
    delete: {
      one: {
        tradingPartnerGlobalLocationNumber: () =>
          `/api/v1/tradingpartner/delete/one/tradingpartnergloballocationnumber`,
        tradingPartnerAddress: () =>
          `/api/v1/tradingpartner/delete/one/tradingpartneraddress`,
        tradingPartnerContact: () =>
          `/api/v1/tradingpartner/delete/one/tradingpartnercontact`,
        tradingPartnerContactDetail: () =>
          `/api/v1/tradingpartner/delete/one/tradingpartnercontactdetail`,
        tradingPartnerAccountNumber: () =>
          `/api/v1/tradingpartner/delete/one/tradingpartneraccountnumber`,
      },
    },
  },
  illegitReport: {
    create: {
      one: () => '/api/v1/illegitreport/create/one',
      send: () => `/api/v1/illegitreport/create/send`
    },
    read: {
      many: {
        by: {
          search: () => `/api/v1/illegitreport/read/many/by/search`
        }
      },
      count: {
        by: {
          userId: () => `/api/v1/illegitreport/read/count/by/userid`
        }
      },
      one: {
        by: {
          id: () => `/api/v1/illegitreport/read/one/by/id`
        }
      }
    },
    update: {
      one: {
        by: {
          id: () => `/api/v1/illegitreport/update/one/by/id`
        }
      }
    },
    delete: {}
  }
};

export default endpoints;
