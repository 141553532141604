import { useEffect, useState } from "react";
import { logout, toast } from "../../func";
import Axios from 'axios';

const ChangePassword = props => {

    const initValues = () => ({
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: '',
    })

    const initPassCheck = () => ({
        hasSpecial: false,
        hasUpper: false,
        hasLower: false,
        hasNumber: false,
        hasEight: false
    })

    const [values, setValues] = useState(initValues());
    const [show, setShow] = useState(false);
    const [passCheck, setPassCheck] = useState(initPassCheck())

    const onChange = ({ target: { id, value } }) => setValues(p => ({ ...p, [id]: value }))

    const submit = e => {
        e.preventDefault();

        Axios.post('/api/v1/user/update/changepassword', {
            ...JSON.parse(localStorage.getItem('auth_data')),
            ...values
        })
            .then(result => {
                toast(result.data)
                setValues(initValues());
            })
            .catch(logout)
    }

    useEffect(() => {
        const { newPassword } = values;
        const obj = {};

        obj.hasUpper = /[A-Z]/.test(newPassword) ? true : false;
        obj.hasLower = /[a-z]/.test(newPassword) ? true : false;
        obj.hasNumber = /\d/.test(newPassword) ? true : false;
        obj.hasSpecial = /[\W_]/.test(newPassword) ? true : false;
        obj.hasEight = newPassword.length >= 8 ? true : false;
        obj.noSpaces = /\s/.test(newPassword) ? false : true;

        setPassCheck(obj);
    }, [values.newPassword])

    return (
        <div>
            <div className="row">
                <p><b>Passwords must satisfy the following criteria:</b></p>
                <ul>
                    <li className={passCheck.hasSpecial ? 'green-text bolder' : ''}>One special character (non alphanumeric)</li>
                    <li className={passCheck.hasUpper ? 'green-text bolder' : ''}>One uppercase letter: [A-Z]</li>
                    <li className={passCheck.hasLower ? 'green-text bolder' : ''}>One lowercase letter: [a-z]</li>
                    <li className={passCheck.hasNumber ? 'green-text bolder' : ''}>One number: [0-9]</li>
                    <li className={passCheck.hasEight ? 'green-text bolder' : ''}>At least 8 characters</li>
                    <li className={passCheck.noSpaces ? 'green-text bolder' : 'red-text bolder'}>No Spaces</li>
                </ul>
            </div>
            <div className="row">
                <div className="input-field col s12 m4">
                    <input id="oldPassword" type={show ? 'text' : 'password'} value={values.oldPassword} onChange={onChange} />
                    <label htmlFor="oldPassword">Current Password</label>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12 m4">
                    <input id="newPassword" type={show ? 'text' : 'password'} value={values.newPassword} onChange={onChange} />
                    <label htmlFor="newPassword">New Password</label>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12 m4">
                    <input id="repeatNewPassword" type={show ? 'text' : 'password'} value={values.repeatNewPassword} onChange={onChange} />
                    <label htmlFor="repeatNewPassword">Repeat New Password</label>
                </div>
            </div>
            <div className="row">
                <div className="col s12 m4 l2">
                    <a
                        href="/"
                        onMouseDown={e => { e.preventDefault(); setShow(true) }}
                        onMouseUp={e => { e.preventDefault(); setShow(false) }}
                        onMouseLeave={e => { e.preventDefault(); setShow(false) }}
                        onClick={e => e.preventDefault()}
                        className="right"
                    >Show Passwords</a>
                </div>
            </div>
            <div className="row" style={{ marginTop: '40px' }}>
                <div className="col s12 m4 l2">
                    <a
                        href="/"
                        onClick={submit}
                        className="btn btn-small blue white-text waves-effect waves-light col s12"
                        disabled={Object.values(passCheck).every(h => h) && values.newPassword === values.repeatNewPassword ? '' : 'disabled'}
                    >{values.newPassword === values.repeatNewPassword ? 'Submit' : 'Passwords Do Not Match'}</a>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;