import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { TradingPartnerGlobalLocationNumber } from '../../../common/models';

interface IProps {
  globalLocationNumbers: Partial<TradingPartnerGlobalLocationNumber>[];
  setGlobalLocatoinNumbers: Dispatch<
    SetStateAction<Partial<TradingPartnerGlobalLocationNumber>[]>
  >;
}

const GlobalLocationNumbers = ({
  globalLocationNumbers,
  setGlobalLocatoinNumbers,
}: IProps) => {
  const initValues = (): Partial<TradingPartnerGlobalLocationNumber> => ({
    globalLocationNumber: '',
  });

  const modal = useRef<HTMLDivElement>(null);
  const [values, setValues] = useState<
    Partial<TradingPartnerGlobalLocationNumber>
  >(initValues());
  const [edit, setEdit] = useState<Boolean>(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);

  useLayoutEffect(() => {
    M.Modal.init(document.querySelectorAll('#gln-modal'), { onCloseStart: () => setEdit(false) });
    const modalElement = modal.current;

    return () => {
      if (modalElement) M.Modal.getInstance(modalElement)?.destroy();
    };
  }, []);

  const onChange = ({ target: { id, value } }: ChangeEvent<HTMLInputElement>) =>
    setValues((prev) => ({ ...prev, [id]: value }));

  const openModal = () => {
    if (modal.current) M.Modal.getInstance(modal.current)?.open();
  };

  const closeModal = () => {
    if (modal.current) M.Modal.getInstance(modal.current)?.close();
    setValues(initValues());
  };

  const addGlobalLocationNumber = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    openModal();
  };

  const saveGlobalLocationNumber = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    if (edit) {
      if (editIndex === null) {
        return;
      }
      setGlobalLocatoinNumbers((prev) => {
        prev.splice(editIndex, 1, values);
        return prev;
      })
      setEdit(false);
    } else {
      setGlobalLocatoinNumbers((prev) => [...prev, values]);
    }
    closeModal();
    setValues(initValues);
  };

  const cancel = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    closeModal();
    setValues(initValues());
  };

  const editGlobalLocationNumber = (
    event: MouseEvent<HTMLAnchorElement>,
    index: number
  ) => {
    event?.preventDefault();
    openModal();
    setValues(globalLocationNumbers[index]);
    setEdit(true);
    setEditIndex(index);
    setGlobalLocatoinNumbers([...globalLocationNumbers]);
  }

  const deleteGlobalLocationNumber = (
    event: MouseEvent<HTMLAnchorElement>,
    index: number
  ) => {
    event?.preventDefault();
    globalLocationNumbers.splice(index, 1);
    setGlobalLocatoinNumbers([...globalLocationNumbers]);
  };

  return (
    <>
      <div className="row">
        <h5>Global Location Numbers</h5>
        <div className='divider' />
      </div>
      <div className="row">
        <table className="highlight">
          <thead>
            <tr>
              <th>GLN</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {globalLocationNumbers?.map((gln, index) => (
              <tr key={index}>
                <td style={{ padding: '2px' }}>{gln.globalLocationNumber}</td>
                <td style={{ padding: '2px' }}>
                  <a
                    href="/"
                    onClick={(event) =>
                      editGlobalLocationNumber(event, index)
                    }
                  >
                    Edit
                  </a>
                </td>
                <td style={{ padding: '2px' }}>
                  <a
                    href="/"
                    onClick={(event) =>
                      deleteGlobalLocationNumber(event, index)
                    }
                  >
                    Delete
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col s12 m3 offset-m9">
          <a href="/" className="right" onClick={addGlobalLocationNumber}>
            Add Global Location Number
          </a>
        </div>
      </div>
      <div ref={modal} id="gln-modal" className="modal">
        <div className="modal-content">
          <div className="row">
            <h6 className="center">Add Global Location Number</h6>
          </div>
          <div className="row">
            <div className="input-field col s12 m5">
              <input
                id="globalLocationNumber"
                type="text"
                value={values.globalLocationNumber}
                onChange={onChange}
              />
              <label htmlFor="globalLocationNumber">
                Global Location Number
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col s6 l3 offset-l6">
              <a
                href="/"
                className="btn-small blue white-text waves-effect waves-light col s12"
                onClick={saveGlobalLocationNumber}
              >
                {edit ? 'Save Changes' : 'Add GLN'}
              </a>
            </div>
            <div className="col s6 l3">
              <a
                href="/"
                className="btn-small blue white-text waves-effect waves-light col s12"
                onClick={cancel}
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GlobalLocationNumbers;
