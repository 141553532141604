export enum TradingPartnerType{
  upstream = 'upstream',
  downstream = 'downstream',
  both = 'both'
}

export enum AddressType {
  physical = 'physical',
  mailTo = 'mail-to',
  billTo = 'bill-to',
  other = 'other',
}

export enum ContactType {
  phone = 'phone',
  fax = 'fax',
  email = 'email',
  cell = 'cell',
}

export enum IncidentReportType {
  initial = 'initial',
  followup = 'followup',
  terminate = 'terminate'
}

export enum IncidentClassification {
  counterfeit = 'counterfeit',
  diverted = 'diverted',
  stolen = 'stolen',
  intentionalAdulteration = 'intentional adulteration',
  unfitForDistribution = 'unfit for distribution',
  fraudulentTransaction = 'fraudulentTransaction'
}

export enum IncidentDrugUse {
  humanUse = 'human use',
  other = 'other'
}

export enum IncidentDrugDescription {
  finishedPrescriptionDrug = 'finished prescription drug',
  vaccine = 'vaccine',
  plasmaDerivative = 'plasma derivative',
  allergenic = 'allergenic',
  multiple = 'multiple'
}

export enum IncidentDosageForm {
  tablet = 'tablet',
  capsule = 'capsule',
  sublingual = 'sublingual',
  aerosol = 'aerosol',
  oralLiquid = 'oral liquid',
  injectable = 'injectable',
  topical = 'topical',
  suppository = 'suppository',
  other = 'other',
  multiple = 'multiple'
}

export enum IncidentCompanyCategory {
  manufacturer = 'manufacturer',
  wholesaleDistributor = 'wholesale distributor',
  dispenser = 'dispenser (pharmacy)',
  repackager = 'repackager'
}

export enum GLNDisposition {
  shipTo = 'SHIP TO',
  billTo = 'BILL TO',
  both = 'BOTH'
}