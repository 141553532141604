import customer from './customer.ts';
import illegitReport from './illegitReport.ts';
import customerLocationGlobalLocationNumber from './customerLocationGlobalLocationNumber.ts';
import state from './state.ts';
import tradingPartner from './tradingPartner.ts';

const api = {
  state,
  tradingPartner,
  customer,
  illegitReport,
  customerLocationGlobalLocationNumber
};

export default api;
