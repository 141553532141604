import { fetch } from './helpers.ts';
import endpoints from '../endpoints.ts';
import { AddressType, ContactType, TradingPartnerType } from '../enums.ts';
import { Contact, Toast, TradingPartner } from '../models.ts';

const tradingPartner = {
  create: {
    one: async (data: Partial<TradingPartner>): Promise<Toast | null> => {
      const url = endpoints.tradingPartner.create.one();
      return await fetch<Toast>(url, 'POST', data);
    },
    tradingPartnerGlobalLocationNumber: async (data: {
      tradingPartnerId: number;
      globalLocationNumber: string;
    }): Promise<Toast | null> => {
      const url =
        endpoints.tradingPartner.create.tradingPartnerGlobalLocationNumber();
      return await fetch<Toast>(url, 'POST', data);
    },
    tradingPartnerAddress: async (data: {
      tradingPartnerId: number;
      stateId: number | null;
      addressType: AddressType;
      name: string;
      addressLineOne: string | null;
      addressLineTwo: string | null;
      city: string | null;
      postalCode: string | null;
    }): Promise<Toast | null> => {
      const url = endpoints.tradingPartner.create.tradingPartnerAddress();
      return await fetch<Toast>(url, 'POST', data);
    },
    tradingPartnerContact: async (data: {
      tradingPartnerId: number;
      firstName: string;
      lastName: string | null;
      title: string | null;
      contactDetails:
        | {
            contactType: ContactType;
            contactDetail: string;
          }[]
        | null;
    }): Promise<Toast | null> => {
      const url = endpoints.tradingPartner.create.tradingPartnerContact();
      return await fetch<Toast>(url, 'POST', data);
    },
    tradingPartnerContactDetail: async (data: {
      tradingPartnerContactId: number;
      contactType: ContactType;
      contactDetail: string;
    }): Promise<Toast | null> => {
      const url = endpoints.tradingPartner.create.tradingPartnerContactDetail();
      return await fetch<Toast>(url, 'POST', data);
    },
    tradingPartnerAccountNumber: async (data: {
      tradingPartnerId: number;
      accountNumber: string;
    }): Promise<Toast | null> => {
      const url = endpoints.tradingPartner.create.tradingPartnerAccountNumber();
      return await fetch<Toast>(url, 'POST', data);
    },
  },
  read: {
    one: {
      tradingPartner: {
        by: {
          id: async (id: number): Promise<TradingPartner | null> => {
            const url =
              endpoints.tradingPartner.read.one.tradingPartner.by.id();
            return await fetch<TradingPartner>(url, 'GET', { id });
          },
        },
      },
    },
    many: {
      tradingPartners: {
        search: async (data: {
          name: string;
          gln: string;
          stateId: string | number;
          type: TradingPartnerType;
        }): Promise<Partial<TradingPartner>[] | null> => {
          const url =
            endpoints.tradingPartner.read.many.tradingPartners.search();
          return await fetch<Partial<TradingPartner>[]>(url, 'GET', data);
        },
      },
    },
  },
  update: {
    one: {
      tradingPartner: async (data: {
        id: number;
        name: string;
        type: TradingPartnerType;
      }): Promise<Toast | null> => {
        const url = endpoints.tradingPartner.update.one.tradingPartner();
        return await fetch<Toast>(url, 'PUT', data);
      },
      tradingPartnerGlobalLocationNumber: async (data: {
        id: number | string;
        globalLocationNumber: string;
      }): Promise<Toast | null> => {
        const url =
          endpoints.tradingPartner.update.one.tradingPartnerGlobalLocationNumber();
        return await fetch<Toast>(url, 'PUT', data);
      },
      tradingPartnerAddress: async (data: {
        id: number | string;
        stateId: number | null;
        addressType: AddressType;
        name: string;
        addressLineOne: string | null;
        addressLineTwo: string | null;
        city: string | null;
        postalCode: string | null;
      }): Promise<Toast | null> => {
        const url = endpoints.tradingPartner.update.one.tradingPartnerAddress();
        return await fetch<Toast>(url, 'PUT', data);
      },
      tradingPartnerContact: async (data: {
        id: number;
        firstName: string;
        lastName: string | null;
        title: string | null;
      }) => {
        const url = endpoints.tradingPartner.update.one.tradingPartnerContact();
        return await fetch<Toast>(url, 'PUT', data);
      },
      tradingPartnerAccountNumber: async (data: {
        id: number;
        accountNumber: string;
      }): Promise<Toast | null> => {
        const url =
          endpoints.tradingPartner.update.one.tradingPartnerAccountNumber();
        return await fetch<Toast>(url, 'PUT', data);
      },
    },
  },
  delete: {
    one: {
      tradingPartnerGlobalLocationNumber: async (
        id: number
      ): Promise<Toast | null> => {
        const url =
          endpoints.tradingPartner.delete.one.tradingPartnerGlobalLocationNumber();
        return await fetch<Toast>(url, 'DELETE', { id });
      },
      tradingPartnerAddress: async (id: number): Promise<Toast | null> => {
        const url = endpoints.tradingPartner.delete.one.tradingPartnerAddress();
        return await fetch<Toast>(url, 'DELETE', { id });
      },
      tradingPartnerContact: async (id: number): Promise<Toast | null> => {
        const url = endpoints.tradingPartner.delete.one.tradingPartnerContact();
        return await fetch<Toast>(url, 'DELETE', { id });
      },
      tradingPartnerContactDetail: async (
        id: number
      ): Promise<Toast | null> => {
        const url =
          endpoints.tradingPartner.delete.one.tradingPartnerContactDetail();
        return await fetch<Toast>(url, 'DELETE', { id });
      },
      tradingPartnerAccountNumber: async (
        id: number
      ): Promise<Toast | null> => {
        const url =
          endpoints.tradingPartner.delete.one.tradingPartnerAccountNumber();
        return await fetch<Toast>(url, 'DELETE', { id });
      },
    },
  },
};

export default tradingPartner;
