import React, {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  CustomerLocation,
  CustomerLocationGlobalLocationNumber,
  GlobalLocationNumber,
} from '../../common/models.ts';
import api from '../../common/api/index.ts';
import { MdVerified } from 'react-icons/md';
import { GLNDisposition } from '../../common/enums.ts';

const MyGLNs = () => {
  const selectRefs = useRef<HTMLSelectElement[]>([])
  const modal = useRef<HTMLDivElement>(null);

  const initValues = (): {
    customerLocationId?: number;
    globalLocationNumber: string;
    disposition: GLNDisposition | ''
  } => ({
    customerLocationId: undefined,
    globalLocationNumber: '',
    disposition: ''
  });

  const [customerLocationList, setCustomerLocationList] = useState<
    CustomerLocation[]
  >([]);
  const [
    customerLocationGlobalLocationNumberList,
    setCustomerLocationGlobalLocationNumberList,
  ] = useState<CustomerLocationGlobalLocationNumber[]>([]);
  const [values, setValues] = useState(initValues);
  useState<GlobalLocationNumber>();
  const [
    selectedCustomerLocationGlobalLocationNumber,
    setSelectedCustomerLocationGlobalLocationNumber,
  ] = useState<CustomerLocationGlobalLocationNumber>();

  const getModal = () =>
    modal.current ? M.Modal.getInstance(modal.current) : null;

  const getCustomerLocationList = async () => {
    const list = await api.customer.read.many.by.userId();
    setCustomerLocationList(list || []);
  };

  const getGlobalLocationNumberList = async () => {
    const list =
      await api.customerLocationGlobalLocationNumber.read.many.by.userId();
    setCustomerLocationGlobalLocationNumberList(list || []);
  };

  useEffect(() => {
    getCustomerLocationList();
    getGlobalLocationNumberList();
    if (modal.current) M.Modal.init(modal.current);
  }, []);

  useEffect(() => {
    const refs = selectRefs.current;
    if (refs) {
      refs.forEach(ref => {
        if (ref) {
          M.FormSelect.init(ref);
        }
      })
    }
    const customerLocationId = customerLocationList?.[0]?.id;
    if (customerLocationId)
      setValues((prev) => ({ ...prev, customerLocationId }));
    return () => {
      if (refs) {
        refs.forEach(ref => {
          if (ref) {
            M.FormSelect.getInstance(ref)?.destroy();
          }
        })
      }
    }
  }, [customerLocationList]);

  const onChange = ({
    target: { id, value },
  }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
    setValues((prev) => ({ ...prev, [id]: value }));

  const editGln = (
    event: MouseEvent<HTMLAnchorElement>,
    clgln: CustomerLocationGlobalLocationNumber
  ) => {
    event.preventDefault();
    setSelectedCustomerLocationGlobalLocationNumber(clgln);
    setValues((prev) => ({
      ...prev,
      globalLocationNumber: clgln.globalLocationNumber.globalLocationNumber,
    }));
    getModal()?.open();
    setTimeout(() => {
      M.updateTextFields();
    }, 50);
  };

  const cancel = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    setSelectedCustomerLocationGlobalLocationNumber(undefined);
    getModal()?.close();
  };

  const createGln = async (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();

    if (values.customerLocationId) {
      const response =
        await api.customerLocationGlobalLocationNumber.create.one({
          globalLocationNumber: values.globalLocationNumber.trim(),
          customerLocationId: values.customerLocationId,
          disposition: values.disposition
        });

      if (response) {
        getGlobalLocationNumberList();
        getModal()?.close();
      }
    }
  };

  const updateGln = async (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    if (selectedCustomerLocationGlobalLocationNumber) {
      const response =
        await api.customerLocationGlobalLocationNumber.update.one({
          newGlobalLocationNumber: values.globalLocationNumber.trim(),
          disposition: values.disposition,
          customerLocationId:
            selectedCustomerLocationGlobalLocationNumber.customerLocation.id,
          globalLocationNumberId:
            selectedCustomerLocationGlobalLocationNumber.globalLocationNumber
              .id,
        });

      if (response) {
        getGlobalLocationNumberList();
        getModal()?.close();
      }
    }
  };

  const addGln = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    setSelectedCustomerLocationGlobalLocationNumber(undefined);
    setValues((prev) => ({ ...prev, globalLocationNumber: '' }));
    getModal()?.open();
  };

  const deleteGln = async (
    event: MouseEvent<HTMLAnchorElement>,
    clgln: CustomerLocationGlobalLocationNumber
  ) => {
    event?.preventDefault();
    if (
      window.confirm(
        'Are you sure you want to delete this Global Location Number?'
      )
    ) {
      const {
        customerLocation: { id: customerLocationId },
        globalLocationNumber: { id: globalLocationNumberId },
      } = clgln;
      const response =
        await api.customerLocationGlobalLocationNumber.delete.one({
          customerLocationId,
          globalLocationNumberId,
        });

      if (response) getGlobalLocationNumberList();
    }
  };

  return (
    <>
      <div className="row">
        <h5 className="center">Manage your pharmacy's GLNs</h5>
      </div>
      {customerLocationList?.length > 1 && (
        <div className="row">
          <div className="input-field col s12 m3">
            <select
              ref={(el) => {
                if (el) {
                  selectRefs.current.push(el)
                }
              }}
              id="customerLocationId"
              value={values.customerLocationId}
              onChange={({ target: { value } }) =>
                setValues((prev) => ({
                  ...prev,
                  customerLocationId: parseInt(value, 10),
                }))
              }
            >
              {customerLocationList?.map(({ id, name }: CustomerLocation) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      <div className="row">
        <a href="/" onClick={addGln}>
          Add Global Location Number
        </a>
        <table className="highlight">
          <thead>
            <tr>
              <th>Global Location #</th>
              <th>Disposition</th>
              <th>Verified</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {customerLocationGlobalLocationNumberList
              ?.filter(
                ({ customerLocation: { id } }) =>
                  id === values.customerLocationId
              )
              .map((clgln) => (
                <tr key={clgln.globalLocationNumber.id}>
                  <td style={{ padding: '2px' }}>
                    {clgln.globalLocationNumber.globalLocationNumber}
                  </td>
                  <td style={{ padding: '2px' }}>
                    {clgln.globalLocationNumber.disposition}
                  </td>
                  <td style={{ padding: '2px' }}>
                    {clgln.globalLocationNumber.isVerified ? (
                      <MdVerified
                        style={{ fontSize: '24px' }}
                        className="green-text text-darken-2"
                      />
                    ) : (
                      <span className="orange-text bold text-darken-2">
                        Pending
                      </span>
                    )}
                  </td>
                  <td style={{ padding: '2px' }}>
                    {clgln.isVerified ? null : (
                      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <a href="/" onClick={(event) => editGln(event, clgln)}>
                          Edit
                        </a>
                        <a
                          href="/"
                          onClick={(event) => deleteGln(event, clgln)}
                        >
                          Delete
                        </a>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div ref={modal} id="gln-modal" className="modal">
        <div className="modal-content">
          <div className="row">
            <h6 className="center">
              {selectedCustomerLocationGlobalLocationNumber ? 'Update' : 'Add'}{' '}
              Global Location Number
            </h6>
          </div>
          <div className="row">
            <div className="input-field col s6 m5">
              <input
                id="globalLocationNumber"
                type="text"
                value={values.globalLocationNumber}
                onChange={onChange}
              />
              <label htmlFor="globalLocationNumber">Global Location #</label>
            </div>
            <div className="input-field col s6 m5">
              <select
                id="disposition"
                value={values.disposition}
                onChange={onChange}
                ref={(el) => {
                  if (el) {
                    selectRefs.current.push(el)
                  }
                }}
              >
                <option value={''}></option>
                <option value={'SHIP TO'}>Ship To</option>
                <option value={'BILL TO'}>Bill To</option>
                <option value={'BOTH'}>Both</option>
              </select>
              <label htmlFor="disposition">Disposition</label>
            </div>
          </div>
          <div className="row">
            <div className="col s6 l3 offset-l6">
              <a
                href="/"
                className="btn-small blue white-text waves-effect waves-light col s12"
                onClick={
                  selectedCustomerLocationGlobalLocationNumber
                    ? updateGln
                    : createGln
                }
              >
                {selectedCustomerLocationGlobalLocationNumber
                  ? 'Update'
                  : 'Add'}{' '}
                GLN
              </a>
            </div>
            <div className="col s6 l3">
              <a
                href="/"
                className="btn-small blue white-text waves-effect waves-light col s12"
                onClick={cancel}
              >
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyGLNs;
