import { Link } from "react-router-dom";

const HomePage = props => {

    return (
        <div>
            <div className="row">
                <div className="col s12 m6">
                    <div className="row">
                        <h4 className="blue-grey-text text-darken-2">E-Pedigree Menu</h4>
                    </div>
                    <div className="row">
                        <ImageLink to="/searchmeds" icon="content_paste" title="My Pedigrees" />
                        <ImageLink to="/sendapedigree" icon="content_paste_go" title="Send a Pedigree" />
                        {/* <ImageLink to="/inputmeds" icon="add_circle_outline" title="Create A Pedigree" /> */}
                        <ImageLink to="/reportanincident" icon="report_problem" title="Report An Incident" />
                        <ImageLink to="/incidenttracker" icon="sync_problem" title="Incident Tracker" warnings={props.warnings} />
                        {/* <ImageLink to="/medicationHistory" icon="history" title="Medication History" /> */}
                    </div>
                </div>
                <div className="col s12 m6">
                    <div className="row">
                        <h4 className="blue-grey-text text-darken-2">Your Pharmacy</h4>
                    </div>
                    {/* <ImageLink to="/recalls" icon="fast_rewind" title="Recalls In Your Pharmacy" /> */}
                    {/* <ImageLink to="/shortdatedandexpiredmeds" icon="av_timer" title="Shortdated and Expired Meds" /> */}
                    {/* <ImageLink to="/hazardousmeds" icon="do_not_disturb_alt" title="Hazardous Medications and MSDS" /> */}
                    <ImageLink to="/reports" icon="sim_card_download" title="Reports" />

                </div>
            </div>
        </div>
    )
}

export default HomePage;

const ImageLink = props => (
    <div className="col s12" >
        <Link
            className="home-page-image-link"
            to={props.to}
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                marginBottom: '20px',
                position: 'relative'
            }}>
            <i
                className="material-icons large"
                style={{
                    padding: '12px 4px',
                    border: '3px solid #000',
                    borderRadius: '4px',
                    marginRight: '20px',
                    color: 'black'
                }}
            >{props.icon}</i>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <h5 className="black-text">{props.title}</h5>
                {props.warnings !== null && props.warnings > 0 ?
                    <h6 className="red-text" style={{ fontWeight: '500' }}>You currently have {props.warnings} notification(s) without an incident number</h6>
                    : null
                }
            </div>
        </Link>
    </div>
)