import { useState } from "react";
import { logout, toast } from "../../func";
import Axios from 'axios';

const OtherSettings = props => {

    const initValues = () => ({
        suspectMedEmailAddress: ''
    })

    const [values, setValues] = useState(initValues());

    const onChange = ({ target: { id, value } }) => setValues(p => ({ ...p, [id]: value }))

    const submit = e => {
        e.preventDefault();

        Axios.post('/api/v1/user/update/othersettings', {
            ...JSON.parse(localStorage.getItem('auth_data')),
            ...values
        })
            .then(result => {
                toast(result.data)
                setValues(initValues());
            })
            .catch(logout)
    }

    return (
        <div>
                <p><b>Other Settings</b></p>
            <div className="row">
                <div className="input-field col s12 m4">
                    <input id="suspectMedEmailAddress" type={'text'} value={values.suspectMedEmailAddress} onChange={onChange} />
                    <label htmlFor="suspectMedEmailAddress">Suspect Med Email Address</label>
                </div>
            </div>
            <div className="row" style={{ marginTop: '40px' }}>
                <div className="col s12 m4 l2">
                    <a
                        href="/"
                        onClick={submit}
                        className="btn btn-small blue white-text waves-effect waves-light col s12"
                    >Submit</a>
                </div>
            </div>
        </div>
    )
}

export default OtherSettings;